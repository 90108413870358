import React, { useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
    textInput,
    container,
    formSet,
    hCaptcha,
    center,
    error as errorStyle,
    spin,
} from '../styles/pages/contact.module.scss';
import Heading from '../components/heading';

const Contact = () =>
{
    const [loaded, setScriptLoaded] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const handleFocus = () =>
    {
        if (!loaded)
        {
            setScriptLoaded(true);
            const script = document.createElement('script');
            script.src = 'https://hcaptcha.com/1/api.js';
            script.async = true;
            script.defer = true;

            document.head.appendChild(script);
        }
    };
    const checkCaptcha = async () =>
    {
        const captcha = await document.querySelector('[name=h-captcha-response]');
        if (captcha && captcha.value)
        {
            setSuccess('');
            await setCaptchaError('');
            return true;
        }

        await setCaptchaError('Please Enter A Valid Captcha');
        return false;
    };
    if (typeof window !== 'undefined')
    {
        window.checkCaptcha = checkCaptcha;
    }

    const formSubmit = async (e) =>
    {
        const captchaErrorBool = await checkCaptcha();
        e.preventDefault();
        if (!captchaError && captchaErrorBool)
        {
            const form = e.target.elements;
            const name = form.name.value;
            const email = form.email.value;
            const message = form.message.value;
            const captcha = form['h-captcha-response'].value;
            setSubmitting(true);
            fetch('https://api.locally.link/email/marketing', {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    email,
                    message,
                    captcha,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
            .then((response) =>
            {
                console.log(captchaError);

                if (!response.ok)
                {
                    setError(JSON.stringify(response.json()));
                }
                return response.json();
            })
            .then((json) =>
            {
                // Reset h-captcha after successful submit
                // eslint-disable-next-line no-undef
                hcaptcha.reset();

                e.target.reset();
                setSubmitting(false);
                setSuccess('Thank you for your enquiry we will be in touch shortly');
            });
            // const name = console.log(e);
        }
    };
    return (
        <Layout location="contact" >
            <SEO
                title="Contact Us Now"
            />
            <div className={container}>
                <Heading size='h2' text="Contact Us" />
                <blockquote>
                    <p>
                        Got questions about Locally? Having issues with the service? Leave us a message here and we will do our best to get back to you as soon as possible, and within two business days.
                        If you are having problems with this form email us at <a href="mailto:hello@locally.link">hello@locally.link</a>
                    </p>
                </blockquote>
                <form onSubmit={formSubmit}>
                    <div className={formSet}>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" required className={textInput} onFocus={() => handleFocus()}/>
                    </div>
                    <div className={formSet}>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" required className={textInput} onFocus={() => handleFocus()}/>
                    </div>
                    <div className={formSet}>
                        <label htmlFor="message">Message</label>
                        <textarea id="message" required onFocus={() => handleFocus()} />
                    </div>
                    <div className={center}>
                        <div className={`${hCaptcha} h-captcha`} recaptchacompat="off" data-sitekey="fa3e55aa-9810-4dc1-8445-bcd4d306e2e2" data-callback="checkCaptcha"></div>
                        {captchaError && <p className={errorStyle}>{captchaError}</p>}
                        <button>
                            {submitting && <div className={spin}></div>}
                            {!submitting && 'Submit'}
                        </button>
                    </div>
                    {error && <div className={errorStyle}>
                        {error}
                    </div>}
                    {success && <div>
                        {success}
                    </div>}
                </form>
            </div>
        </Layout>
    );
};

export default Contact;
